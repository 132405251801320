import React from 'react'

import Question from './Question'
import AnswerRowAccordion from './AnswerRowAccordion'
import AnswerRow from './AnswerRow'
import { ResultsDataContext } from '../DataContext';


/** Calculate averages for a group of questions. Lookup the results from resultsLookup */
const calculateAverages = (questions, resultsLookup) => {
  // get the initial rows with 0-counts in bins
  const firstId = Object.keys(questions)[0]
  const groupsForAnswers = resultsLookup[firstId]
  if (groupsForAnswers === undefined) {
    return null  // odd bordercase that probably happens with custom questions
  }
  const initialAvgCalculator = groupsForAnswers.map((group) => [group.group, [0, 0, 0, 0, 0], []])
  const averageBins = Object.values(questions)
    .reduce(
      (avgRows, { question }) => {
        const answers = resultsLookup[question.id]
        if (answers === undefined) {
          console.warn(`Answers is undefined for ${question.id}, results lookup=${resultsLookup}`)
          return []
        }
        return answers.map(({ group, bins, avg }, rowIndex) => {
          return [
            group,
            sumUpBins(avgRows, bins, 1, rowIndex, question),
            // for reversed semantics, calculated the opposite value to average
            avgRows[rowIndex][2].concat(question.sem_rev === true ? (100 - avg) : avg),
          ]
        })
      },
      initialAvgCalculator)
    .map(([groupName, bins, avgs]) =>
      [
        groupName,
        bins,
        (avgs.reduce((col, avg) => col + avg, 0) / avgs.length)
      ]
    )
  console.log("<< calculateAverages done")
  return averageBins
}

/** Sumup the bins. Reverse if needed by the question. */
const sumUpBins = (avgRows, bins, binsIndex, rowIndex, question) => {
  if (question.sem_rev === true) {
    const newBins = bins.slice()
    newBins.reverse()
    return newBins.map((bin, binIndex) => avgRows[rowIndex][binsIndex][binIndex] + bin)
  }
  return bins.map((bin, binIndex) => avgRows[rowIndex][binsIndex][binIndex] + bin)
}


const AnswersFor = ({ source, question, groups }) => {
  return (
    <div className="answer-source">
      {source && <div className="answer-source__name">{source}</div>}
      <div className="answer-source__rows">
        {
          groups.map(([groupName, bins, _avg, count], index) =>
            <AnswerRow key={index} rowName={groupName} question={question} bins={bins} showAmounts={false} />
          )
        }
      </div>
    </div>
  )
}


const AveragingAnswersFor = ({ source, includedQuestions, resultsLookup }) => {
  const averageQuestion = {
    "choices": [I18N._("very negative"), I18N._("negative"), I18N._("neutral"), I18N._("positive"), I18N._("very positive")],
    "text": null, "id": null, "type": "SC"
  }
  const averages =
    includedQuestions && resultsLookup && Object.keys(includedQuestions).length > 0 ?
      calculateAverages(includedQuestions, resultsLookup) :
      null

  // const allSemanticallyReversed = includedQuestions && Object.values(includedQuestions).every((question) => question.sem_rev === true)
  // averageQuestion.sem_rev = allSemanticallyReversed

  if (averages) {
    return (
      <AnswersFor source={source} question={averageQuestion} groups={averages} />
    )
  } else {
    return null
  }
}


/// The component itself ////////////////////////////////////////////////////////
const ResultsSubcategory = ({ name, id, questions, comparisons, mainSourceName, ...rest }) => {
  console.log(`ResultsSubcategory for ${name} ${id} >>`)

  const questionsToBeAveraged = questions
    .filter(({ question }) => question.type === "SC")
    .reduce((col, question) => {
      col[question.question.id] = question
      return col
    }, {})

  const answerLookup = questions.reduce((collector, { question, answers }) => {
    collector[question.id] = answers
    return collector
  }, {})

  const showAccordion = id !== null

  return (
    <ResultsDataContext.Consumer>
      {({ openAccordionsInitially }) =>
        <div className="area-breakdown">
          <div className="area-breakdown__text">
            <h4>{name}</h4>
          </div>
          <div className="area-breakdown__graph answer-sources">
            <AveragingAnswersFor
              includedQuestions={questionsToBeAveraged}
              source={comparisons.length > 0 ? mainSourceName : ""}
              resultsLookup={answerLookup}
            />
            {
              comparisons.map((comparison) =>
                <AveragingAnswersFor
                  includedQuestions={questionsToBeAveraged}
                  source={comparison.name}
                  key={comparison.name}
                  resultsLookup={comparison.data.results} />
              )}
          </div>

          {showAccordion ?
            <AnswerRowAccordion
              open={openAccordionsInitially}
              answers={questions}
              comparisons={comparisons}
              mainSourceName={mainSourceName} />
            :
            questions.map(({ answers, question }) =>
              <Question
                key={question.id}
                answers={answers}
                question={question}
                comparisons={comparisons}
                mainSourceName={mainSourceName} />
            )
          }
        </div>
      }
    </ResultsDataContext.Consumer>

  )
}

export default ResultsSubcategory